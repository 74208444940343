import React, { PropsWithChildren } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBookmark as faLightBookmark } from '@fortawesome/pro-light-svg-icons';
import { faBookmark as faSolidBookmark } from '@fortawesome/free-solid-svg-icons';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Genre, MovieSlideData } from '../../../domain/work';
import { getFormattedMovieDuration } from '../../../domain/utils/MovieUtils';
import { ZeroWidthNonBreakingSpace } from '../../common/NonBreakingSpace';
import { TFunction, WithT } from 'i18next';
import { DirectorCreditLine } from '../../common/DirectorCreditLine';
import { FavoriteType } from '../../../domain/community';
import { FavoriteToggleButton } from '../FavoriteToggleButton';
import { Link } from '../../../i18n';

library.add(faLightBookmark, faSolidBookmark);

export type MovieTooltipProps = PropsWithChildren<
    {
        movie: MovieSlideData;
    } & WithT
>;

function renderGenres(movie: MovieSlideData): JSX.Element | null {
    const genres: string = map(movie.genres, (genre: Genre): string => {
        return genre.name;
    }).join(', ');

    if (isEmpty(genres)) {
        return null;
    }

    return (
        <li className="genre">
            <span>{genres}</span>
        </li>
    );
}

function renderMovieDuration(movie: MovieSlideData): JSX.Element | null {
    const formattedMovieDuration: string | undefined = getFormattedMovieDuration(movie);
    if (formattedMovieDuration == null) {
        return null;
    }
    return (
        <li>
            <span>{formattedMovieDuration}</span>
        </li>
    );
}

function renderAddToFavoritesToggleButton(movie: MovieSlideData, t: TFunction): JSX.Element {
    return <FavoriteToggleButton itemId={movie.id} favoriteType={FavoriteType.MOVIE} noLabel={true} t={t} />;
}

export function MovieTooltip({ movie, t, children }: MovieTooltipProps): JSX.Element {
    return (
        <div className="MovieTooltip">
            <ul className="MovieTooltip-Infos">
                {renderGenres(movie)}
                <li>
                    <span>{movie.productionYear}</span>
                </li>
                {renderMovieDuration(movie)}
            </ul>
            <div className="MovieTooltip-Title">
                <h3>
                    <Link href={{ pathname: '/movie', query: { id: movie.id } }}>{movie.title}</Link>
                    <span>
                        <ZeroWidthNonBreakingSpace />
                        {renderAddToFavoritesToggleButton(movie, t)}
                    </span>
                </h3>
                <DirectorCreditLine movie={movie} t={t} className="MovieTooltip-Title-Directors" />
            </div>
            <>{children}</>
        </div>
    );
}
